/**
 * 我的资料、菜单权限
 */
import { createAxios } from '@/utils/request'
import { IMeInfoData } from '@/types/myProfile'

const request = createAxios()

/*
* 获取菜单
*/
export const getPermission = () => {
  return request({
    method: 'GET',
    url: '/permission'
  })
}

/*
* 查询个人信息
*/
export const getMeInfo = () => {
  return request({
    method: 'GET',
    url: '/me'
  })
}

/*
* 修改个人信息
*/
export const editMeInfo = (id: number, data: IMeInfoData) => {
  return request({
    method: 'PUT',
    url: `/customer/${id}`,
    data
  })
}
// 获取验证码
export const getAuthentication = (data:any) => {
  return request({
    method: 'post',
    url: '/send/code',
    data
  })
}
// 验证验证码
export const Authenticationcode = (data:any) => {
  return request({
    method: 'post',
    url: '/verify/code',
    data
  })
}
