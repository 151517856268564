
// 导入axios
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router'
import tool from '@/utils/tool'
import DEFAULT_CONFIG from '@/config'
import i18n from '@/locales'

// 处理  类型“AxiosResponse<any, any>”上不存在属性“code”。有其他未定义属性可以继续添加
declare module 'axios' {
  interface AxiosResponse{
    code: number;
    msg: string
  }
}

// 用于限制出现多次异常message
let responseCode : any = null
let isShowLoginTip = false
// 国际化
const { t } = i18n.global as any

// 请求配置
export const createAxios = (config?: AxiosRequestConfig, baseURLa?:any): AxiosInstance => {
  // console.log(baseURLa, 'baseURLa')
  const lang = localStorage.getItem('LANG') || ''
  const instance = axios.create({
    baseURL: baseURLa || process.env.VUE_APP_URL,
    timeout: 60000,
    headers: {
      'Access-Control-Allow-Origin-Type': '*',
      // langId: localStorage.getItem('LANG') || 'zh_CN'
      langId: lang ? JSON.parse(lang) : 'zh_CN'
    },
    withCredentials: true, // 跨域携带cookie
    // transformResponse: [function (data) {
    //   try {
    //     return JSONBig.parse(data)
    //   } catch (err) {
    //     return data
    //   }
    // }],
    ...config // 自定义配置覆盖基本配置
  })

  // 添加请求拦截器
  instance.interceptors.request.use(function (config: any) {
    if (Object.prototype.toString.call(config.data) !== '[object FormData]') {
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    }
    const token = tool.cookie.get('TOKEN_frontend')
    if (token) {
      config.headers[DEFAULT_CONFIG.TOKEN_NAME] = DEFAULT_CONFIG.TOKEN_PREFIX + token
    }
    Object.assign(config.headers, DEFAULT_CONFIG.HEADERS)
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
  )

  // 响应拦截器
  instance.interceptors.response.use(function (response) {
    return response.data
  },
  function (error) {
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      switch (error.response.status) {
        case 400:
          error.message = error.response.data.data[0]
          break
        case 401:
          error.message = error.response.data.msg
          if (!isShowLoginTip) {
            isShowLoginTip = true
            ElMessageBox.alert(t('request.401'), t('common.tips'), {
              showClose: false,
              type: 'warning',
              confirmButtonText: t('common.Relogin'),
              callback: () => {
                isShowLoginTip = false
                router.push('/login')
              }
            })
          }
          break
        case 403:
          error.message = t('request.403') // 拒绝访问
          break
        case 404:
          error.message = t('request.404') // 请求错误,未找到该资源
          break
        case 405:
          error.message = t('request.405') // 请求方法未允许
          break
        case 408:
          error.message = t('request.408') // 请求超时
          break
        case 500:
          error.message = t('request.500') // 服务器端出错
          break
        case 501:
          error.message = t('request.501') // 网络未实现
          break
        case 502:
          error.message = t('request.502') // 网络错误
          break
        case 503:
          error.message = t('request.503') // 服务不可用
          break
        case 504:
          error.message = t('request.504') // 网络超时
          break
        case 505:
          error.message = t('request.505') // http版本不支持该请求
          break
        default:
          error.message = `${t('request.linkError')}${error.response.status}`
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes('timeout')) {
        error.message = t('request.timeout')
      } else {
        error.message = t('request.linkServerError')
      }
    }
    // 在1s内，出现同样的code码只显示一次
    if (!responseCode || (responseCode && responseCode !== error.response.status)) {
      ElMessage.error(error.message)
    }
    responseCode = error.response.status
    setTimeout(() => {
      responseCode = null
    }, 1000)
    return Promise.reject(error.response)
  }
  )

  return instance
}
