import sysConfig from '@/config'
import { createI18n } from 'vue-i18n'
import elZhCn from 'element-plus/dist/locale/zh-cn.mjs'
import elEn from 'element-plus/dist/locale/en.mjs'
import elAr from 'element-plus/dist/locale/ar.mjs'
import zh_CN from './lang/zh-cn'
import en from './lang/en'
import ar from './lang/ar'
const messages = {
  zh_CN: {
    el: elZhCn.el,
    ...zh_CN
  },
  en: {
    el: elEn.el,
    ...en
  },
  ar: {
    el: elAr.el,
    ...ar
  }
}

const i18n = createI18n({
  locale: (window as any).localStorage.getItem('LANG') || sysConfig.LANG,
  fallbackLocale: 'zh_CN',
  globalInjection: true,
  messages
})

export default i18n
