import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw = {
  path: 'NBCertificate',
  name: 'NBCertificate',
  component: RouterView,
  children: [
    // {
    //   path: 'workBench',
    //   name: 'entrustedWorkBench',
    //   component: () => import(/* webpackChunkName: "entrustedWorkBench" */ '@/views/customsClearance/workBench/index.vue')
    // },
    {
      path: 'workBench',
      name: 'NBapplicationWorkBench',
      component: () => import(/* webpackChunkName: "NBapplicationWorkBench" */ '@/views/customsClearance/workBench/index.vue')
    },
    {
      path: 'myApplication',
      name: 'NBapplicationMyApplication',
      component: RouterView,
      children: [
        {
          path: 'fillInApplication',
          name: 'NBapplicationFillInApplication',
          component: () => import(/* webpackChunkName: "entrustedFillInApplication" */ '@/components/applicationForm/applicationInfo.vue')
        },
        {
          path: 'fillInApplication/selectType',
          name: 'NBapplicationSelectType',
          component: () => import(/* webpackChunkName: "entrustedSelectType" */ '@/views/NBCertificate/myApplication/selectType/index.vue')
        },
        {
          path: 'fillInApplication/applicationForm',
          name: 'NBapplicationForm',
          component: () => import(/* webpackChunkName: "applicationForm" */ '@/views/NBCertificate/myApplication/applicationForm/index.vue')
        },
        {
          path: 'fillInApplication/applicationForm/:id',
          name: 'NBentrustedFormDetail',
          component: () => import(/* webpackChunkName: "entrustedFormDetail" */ '@/views/NBCertificate/myApplication/applicationForm/index.vue')
        },
        {
          path: 'applicationDrafts',
          name: 'NBapplicationDrafts',
          component: () => import(/* webpackChunkName: "applicationDrafts" */ '@/views/NBCertificate/applicationDrafts/index.vue')
        },
        {
          path: 'applicationList',
          name: 'NBapplicationList',
          component: () => import(/* webpackChunkName: "applicationList" */ '@/views/NBCertificate/applicationList/index.vue')
        }
      ]
    },
    {
      path: 'myCertificate',
      name: 'NBapplicationCertificate',
      component: () => import(/* webpackChunkName: "entrustedCertificate" */ '@/views/NBCertificate/myCertificate/index.vue')
    },
    {
      path: 'myCertificate/:rfcNo',
      name: 'NBapplicationCertificateDetail',
      component: () => import(/* webpackChunkName: "entrustedCertificateDetail" */ '@/views/NBCertificate/myCertificate/detail.vue')
    }
  ]
}

export default routes
