import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw = {
  path: 'entrustedInspection',
  name: 'entrustedInspection',
  component: RouterView,
  children: [
    {
      path: 'workBench',
      name: 'entrustedWorkBench',
      component: () => import(/* webpackChunkName: "entrustedWorkBench" */ '@/views/customsClearance/workBench/index.vue')
    },
    {
      path: 'myApplication',
      name: 'entrustedMyApplication',
      component: RouterView,
      children: [
        {
          path: 'fillInApplication',
          name: 'entrustedFillInApplication',
          component: () => import(/* webpackChunkName: "entrustedFillInApplication" */ '@/components/applicationForm/applicationInfo.vue')
        },
        {
          path: 'fillInApplication/selectType',
          name: 'entrustedSelectType',
          component: () => import(/* webpackChunkName: "entrustedSelectType" */ '@/views/entrustedInspection/myApplication/selectType/index.vue')
        },
        {
          path: 'fillInApplication/entrustedForm',
          name: 'entrustedForm',
          component: () => import(/* webpackChunkName: "entrustedForm" */ '@/views/entrustedInspection/myApplication/entrustedForm/index.vue')
        },
        {
          path: 'fillInApplication/entrustedForm/:id',
          name: 'entrustedFormDetail',
          component: () => import(/* webpackChunkName: "entrustedFormDetail" */ '@/views/entrustedInspection/myApplication/entrustedForm/index.vue')
        },
        {
          path: 'entrustedDrafts',
          name: 'entrustedDrafts',
          component: () => import(/* webpackChunkName: "entrustedDrafts" */ '@/views/entrustedInspection/entrustedDrafts/index.vue')
        },
        {
          path: 'entrustedList',
          name: 'entrustedList',
          component: () => import(/* webpackChunkName: "entrustedList" */ '@/views/entrustedInspection/entrustedList/index.vue')
        }
      ]
    },
    {
      path: 'myCertificate',
      name: 'entrustedCertificate',
      component: () => import(/* webpackChunkName: "entrustedCertificate" */ '@/views/entrustedInspection/entrustedCertificate/index.vue')
    },
    {
      path: 'myCertificate/:id',
      name: 'entrustedCertificateDetail',
      component: () => import(/* webpackChunkName: "entrustedCertificateDetail" */ '@/views/entrustedInspection/entrustedCertificate/detail.vue')
    }
  ]
}

export default routes
