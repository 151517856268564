import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import i18n from '@/locales'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import API from '@/api/user'
import TOOL from '@/utils/tool'
import 'element-plus/theme-chalk/dark/css-vars.css'
import config from '@/config/index'

const app = createApp(App)
// 挂载全局参数
app.config.globalProperties.$API = API
app.config.globalProperties.$TOOL = TOOL
app.config.globalProperties.$CONFIG = config
app.config.globalProperties.$i18n = i18n.global // 如何使用查看readme

app.use(createPinia())
app.use(router)
// size全局设置表单组件的默认尺寸，zIndex 用于设置弹出组件的层级
app.use(ElementPlus, {
  size: 'default',
  zIndex: 3000
})
app.use(i18n)

app.mount('#app')
