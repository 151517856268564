import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw = {
  path: 'productTesting',
  name: 'productTesting',
  component: RouterView,
  children: [
    {
      path: 'workBench',
      name: 'productTestingWorkBench',
      component: () => import(/* webpackChunkName: "customsClearanceWorkBench" */ '@/views/productTesting/workBench/index.vue')
    },
    {
      path: 'applicationForm',
      name: 'productApplication',
      component: RouterView,
      children: [
        {
          path: 'productTestingDrafts',
          name: 'productTestingDrafts',
          component: () => import('@/views/productTesting/applicationForm/applicationDrafts/index.vue')
        },
        {
          path: 'productTestingList',
          name: 'productTestingList',
          component: () => import('@/views/productTesting/applicationForm/applicationList/index.vue')
        },
        {
          path: 'productTestingDraft',
          name: 'productTestingDraft',
          component: () => import('@/views/productTesting/applicationForm/applicationList/DraftCertificate.vue')
        },
        {
          path: 'productTestingForm',
          name: 'productTestingForm',
          component: () => import('@/views/productTesting/applicationForm/productTestingForm/index.vue')
        },
        {
          path: 'productTestingForm/:id',
          name: 'productTestingFormID',
          component: () => import('@/views/productTesting/applicationForm/productTestingForm/index.vue')
        },
        {
          path: 'NBselectType',
          name: 'NBselectType',
          component: () => import(/* webpackChunkName: "communicationSelectType" */ '@/views/productTesting/NBApplication/NBmyApplication/selectType/index.vue')
        },
        {
          path: 'NBcommunicationForm',
          name: 'NBcommunicationForm',
          component: () => import(/* webpackChunkName: "communicationForm" */ '@/views/productTesting/NBApplication/NBmyApplication/communicationForm/index.vue')
        },
        {
          path: 'NBcommunicationForm/:id',
          name: 'NBcommunicationFormID',
          component: () => import(/* webpackChunkName: "communicationForm" */ '@/views/productTesting/NBApplication/NBmyApplication/communicationForm/index.vue')
        }
      ]
    },
    {
      path: 'myCertificate',
      name: 'productMyCertificate',
      component: () => import(/* webpackChunkName: "myCertificate" */ '@/views/productTesting/myCertificate/index.vue')
    },
    {
      path: 'certificateDetails',
      name: 'productCertificateDetails',
      component: () => import('@/views/productTesting/myCertificate/certificateDetails.vue')
    }
  ]
}

export default routes
