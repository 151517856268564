import CryptoJS from 'crypto-js'
import sysConfig from '@/config'

const tool = {
  /* localStorage */
  data: {
    set: function (key:string, data:object, datetime = 0): void {
      // const cacheValue = {
      //   content: data,
      //   datetime: datetime === 0 ? 0 : new Date().getTime() + datetime * 1000
      // }
      return localStorage.setItem(key, JSON.stringify(data))
    },
    get: function (key:string) {
      try {
        const value = JSON.parse(localStorage.getItem(key) || '')
        if (value) {
          const nowTime = new Date().getTime()
          if (nowTime > value.datetime && value.datetime !== 0) {
            localStorage.removeItem(key)
            return null
          }
          return value
        }
        return null
      } catch (err) {
        return null
      }
    },
    remove: function (key:string) {
      return localStorage.removeItem(key)
    },
    clear: function () {
      return localStorage.clear()
    }
  },
  session: {
    set: function (key:string, data:object, datetime = 0): void {
      // const cacheValue = {
      //   content: data,
      //   datetime: datetime === 0 ? 0 : new Date().getTime() + datetime * 1000
      // }
      return sessionStorage.setItem(key, JSON.stringify(data))
    },
    get: function (key:string) {
      try {
        const value = JSON.parse(sessionStorage.getItem(key) || '')
        if (value) {
          const nowTime = new Date().getTime()
          if (nowTime > value.datetime && value.datetime !== 0) {
            sessionStorage.removeItem(key)
            return null
          }
          return value
        }
        return null
      } catch (err) {
        return null
      }
    },
    remove: function (key:string) {
      return sessionStorage.removeItem(key)
    },
    clear: function () {
      return sessionStorage.clear()
    }
  },
  /* cookie */
  cookie: {
    set: function (name:string, value:string, config = {}) {
      const cfg = {
        expires: null,
        path: null,
        domain: null,
        secure: false,
        httpOnly: false,
        ...config
      }
      let cookieStr = `${name}=${escape(value)}`
      if (cfg.expires) {
        const exp = new Date()
        exp.setTime(exp.getTime() + parseInt(cfg.expires) * 1000)
        cookieStr += `;expires=${exp.toString()}`
      }
      if (cfg.path) {
        cookieStr += `;path=${cfg.path}`
      }
      if (cfg.domain) {
        cookieStr += `;domain=${cfg.domain}`
      }
      document.cookie = cookieStr
    },
    get: function (name:string) {
      const arr = document.cookie.match(new RegExp('(^| )' + name + '=([^;]*)(;|$)'))
      if (arr != null) {
        return decodeURI(arr[2])
      } else {
        return null
      }
    },
    remove: function (name:string) {
      const d = new Date()
      d.setTime(-1)
      const expires = 'expires=' + d.toUTCString()
      document.cookie = name + "=''; " + expires
    }
  },
  /* 日期格式化 */
  toolDATA: {
    dateFormat: function (date:string, fmt = 'yyyy-MM-dd hh:mm:ss') {
      const dates = new Date(date)
      const o = {
        'M+': dates.getMonth() + 1, // 月份
        'd+': dates.getDate(), // 日
        'h+': dates.getHours(), // 小时
        'm+': dates.getMinutes(), // 分
        's+': dates.getSeconds(), // 秒
        'q+': Math.floor((dates.getMonth() + 3) / 3), // 季度
        S: dates.getMilliseconds() // 毫秒
      }
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (dates.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      // for (const k in o) {
      //   if (new RegExp('(' + k + ')').test(fmt)) {
      //     fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
      //   }
      // }
      return fmt
    }
  },
  /* 常用加密 */
  crypot: {
    MD5 (data:string) {
      return CryptoJS.MD5(data).toString()
    },
    BASE64: {
      encrypt (data: any) {
        return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data))
      },
      decrypt (cipher: any) {
        return CryptoJS.enc.Base64.parse(cipher).toString(CryptoJS.enc.Utf8)
      }
    },
    AES: {
      encrypt (data: string, secretKey: string) {
        if (secretKey.length % 8 !== 0) {
          console.warn('[WSTC error]: 秘钥长度需为8的倍数，否则解密将会失败。')
        } else {
          secretKey = sysConfig.LS_ENCRYPTION_key
        }
        data = JSON.stringify(data)
        const result = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
          // iv: CryptoJS.enc.Utf8.parse(config.iv || ''),
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        })
        return result.ciphertext.toString()
      },
      decrypt (cipher: string, secretKey: string) {
        const cipher2 = CryptoJS.enc.Hex.parse(cipher)
        const data = CryptoJS.enc.Base64.stringify(cipher2)
        const result = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
          // iv: CryptoJS.enc.Utf8.parse(config.iv || ''),
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8)
        return JSON.parse(result)
      }
    }
  }
}

export default tool
