/**
 * 我的资料、菜单权限
 */
import { createAxios } from '@/utils/request'

const request = createAxios()
const urlPrefixBase = process.env.VUE_APP_URL_BASE
const urlPrefix = process.env.VUE_APP_URL_CHANNEL
const requestchannel = createAxios(undefined, urlPrefix)
const requestBase = createAxios(undefined, urlPrefixBase)
/*
 * 获取菜单
 */
export const upload = (data: any, config: any) => {
  return request({
    method: 'POST',
    url: '/upload',
    data: data,
    ...config
  })
}

/*
 * 获取国家
 */
export const getCountry = (type?: any) => {
  const params = type ? { parent_id: 0, name: '', current_page: 1, per_page: 999 } : { name: '', current_page: 1, per_page: 999 }
  return request({
    method: 'GET',
    url: '/country',
    params
  })
}

/*
 * 获取字典数据1：key和value的形式返回
 * 页面调用方式：getDictionary(['rfc.apply_type_item', 'client.rfc.rfc_status'])
 */
export const getDictionary = (data: any) => {
  return request({
    method: 'POST',
    url: '/query-dictionary',
    data: {
      slug: data
    }
  })
}

/*
 * 获取字典数据2：对象形式返回
 * 页面调用方式：getDictionary(['rfc.apply_type_item', 'client.rfc.rfc_status'])
 */
export const getDictionaryObject = (data: any) => {
  return request({
    method: 'POST',
    url: '/query-dictionary-obj',
    data: {
      slug: data
    }
  })
}

/*
 * 获取政策法规列表
 */
export const getPolicyList = (params: any) => {
  return request({
    method: 'GET',
    url: '/policy',
    params
  })
}
// 渠道商注册上传文件
export const Channelupload = (data: any): Promise<any> => {
  return requestchannel({
    method: 'POST',
    url: '/file',
    data: data
  }
  )
}
// 获取验证码
export const getVerificationCode = (params: any): Promise<any> => {
  return requestBase({
    method: 'POST',
    url: '/common/zone/sendSms',
    data: params
  }
  )
}
// 微信登录注册
export const wechatLogin = (): Promise<any> => {
  return request({
    method: 'POST',
    url: '/third/redirect-url',
    data: { type: 'WECHAT' }
  }
  )
}
// 云平台资格证书字典
export const getlabComDictionary = (langId:any) => {
  return requestBase({
    method: 'GET',
    url: `/common/getDictionaryChildren?langId=${langId}`
  })
}
// 获取客服信息
export const getWaiterDetail = () => {
  return request({
    method: 'GET',
    url: '/my-waiter'
  })
}
