import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw = {
  path: 'customsClearance',
  name: 'customsClearance',
  component: RouterView,
  children: [
    {
      path: 'workBench',
      name: 'customsClearanceWorkBench',
      component: () => import(/* webpackChunkName: "customsClearanceWorkBench" */ '@/views/customsClearance/workBench/index.vue')
    },
    {
      path: 'applicationForm',
      name: 'applicationForm',
      component: RouterView,
      children: [
        {
          path: 'applicationDrafts',
          name: 'applicationDrafts',
          component: () => import('@/views/customsClearance/applicationForm/applicationDrafts/index.vue')
        },
        {
          path: 'applicationList',
          name: 'applicationList',
          component: () => import('@/views/customsClearance/applicationForm/applicationList/index.vue')
        },
        {
          path: 'draft',
          name: 'draft',
          component: () => import('@/views/customsClearance/applicationForm/applicationList/DraftCertificate.vue')
        },
        {
          path: 'myApplicationForm',
          name: 'myApplicationForm',
          component: () => import('@/views/customsClearance/applicationForm/myApplicationForm/index.vue')
        },
        {
          path: 'myApplicationForm/:id',
          name: 'myApplicationFormID',
          component: () => import('@/views/customsClearance/applicationForm/myApplicationForm/index.vue')
        }
      ]
    },
    {
      path: 'myCertificate',
      name: 'myCertificate',
      component: () => import(/* webpackChunkName: "myCertificate" */ '@/views/customsClearance/myCertificate/index.vue')
    },
    {
      path: 'myCertificate/:id',
      name: 'certificateDetails',
      component: () => import('@/views/customsClearance/myCertificate/certificateDetails.vue')
    }
  ]
}

export default routes
