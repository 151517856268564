export default {
  common: {
    pleaseSelect: 'من فضلك إختر',
    pleaseSelectCoun: 'إختر الدولة',
    pleaseSelectType: 'إختر النوع',
    pleaseEnter: 'من فضلك قم بإدخال',
    StartDate: 'تاريخ البدء',
    Pleaseenter: 'من فضلك قم بإدخال',
    EndDate: 'تاريخ الإنتهاء',
    operations: 'إجراء',
    detail: 'التفاصيل',
    edit: 'تحرير',
    Cancel: 'إلغاء',
    Confirm: 'تأكيد',
    Next: 'الخطوة التالية',
    Search: 'بحث',
    Reset: 'إعادة تعيين',
    clear: 'مسح',
    save: 'حفظ',
    save2: 'تم التأكيد',
    modify: 'تغيير',
    see: 'تحقق',
    back: 'رجوع',
    startDate: 'تاريخ البدء',
    endData: 'تاريخ الإنتهاء',
    toDate: 'حتي',
    index: 'رقم',
    delete: 'حذف',
    view: 'معاينة',
    status: '状态',
    noData: 'لايوجد بيانات',
    noMessage: '暂无消息',
    noFile: '暂无文件',
    nofile: 'لايوجد ملف / شهادة',
    downloadPDF: 'PDFتحميل',
    operateSuccessfully: 'تمت العملية بنجاج',
    submit: 'إرسال',
    agree: '同意',
    close: 'إغلاق',
    tips: 'ملحوظة',
    tipsText: 'إذا كنت بحاجة إلى التعديل ، فيرجى الاتصال بفريق قبول الطلبات عبر البريد الإلكتروني / الهاتف',
    tipsText2: 'هل تريد تأكيد مسودة الشهادة؟',
    outLogin: 'تم الخروج بنجاح',
    exit: 'خروج',
    exitText: 'هل أنت متأكد من تسجيل الخروج من المستخدم الحالي؟',
    AccountInformation: 'معلومات الحساب',
    ChangePassword: 'تغيير الرقم السري ',
    LogOut: 'تسجيل الخروج',
    permission: 'لايوجد إذن أو الصفحة غير موجودة',
    permissiontext: 'الصفحة الحالية ليس لديها إذن للوصول أو تفتح رابط غير موجود. الرجاء التحقق من أذونات الحساب الحالي وإمكانية الوصول إلى الرابط.',
    BacktoHome: 'العودة للصفحة الرئيسية',
    Relogin: 'تسجيل الدخول مرة اخري',
    BackPage: 'الرجوع إلي الصفحة السابقة ',
    DetailedIntroduction: 'مقدمة مفصلة',
    unknownError: 'خطأ غير معروف',
    confirmDelete: 'هل أنت متأكد أنك تريد الحذف؟',
    confirmDeleteAll: 'هل أنت متأكد أنك تريد مسح كافة المعلومات؟',
    sure: 'متأكد',
    nothing: '无',
    logo_tetx: 'نظام معالجة الطلبات عبر الإنترنت',
    perfect: 'يرجى استكمال المعلومات قبل التقديم',
    clickToPerfect: '点击完善 →',
    warning: 'تحذير',
    clause: '服 务 通 用 条 款',
    cal1: '深圳世标检测认证股份有限公司(简称WSCT)、世标检测认证有限公司（香港）、世标鹏程商检技术服务（深圳）有限公司、 世标检测服务(梅州)有限公司、世标检测认证(绍兴)有限公司、中为检测认证(深圳)有限公司、深圳世标计量有限公司、环标检测认证(深圳)有限公司,深圳世标检测认证控股有限公司（包括其所有关联公司，以下简称“本公司”)将根据并遵守此处包含的条款和条件（以下简称“本条件”）应要求向个人或机构提供服务.除非另有通知在公司与主要、服务之间达成的书面协议中，公司提供的服务应受本条件所有条款的约束并受其约束。',
    cal2: '1. 总则',
    cal3: '(a) 除非另有书面协议，或(i)和代表政府、政府团体或任何其它公众实体履行服务的管理法规不一致，或(ii)和当地法律的强制规定不一致，任何WSCT的关联公司或任何它们的代理(单称“公司”)和客户(“契约关系”)所有产生的合同关系，都受本服务通用条款(以下称为“通用条款”)约束。',
    cal4: '(b) 本公司可为发出指令的人或实体(私人、公众或政府)(以下称为“客户”)提供服务。',
    cal5: '(c) 除非本公司事先收到客户相反的书面指令，任何其它方都无权做出指令，特别是关于服务范围或提交做出的报告或证书(“结果报告”)方面的指令。无论是经客户指示或是依照环境、贸易惯例、作法或实践做出判断，客户在此不可撤销地授权本公司提交“结果报告”给第三方。',
    cal6: '2. 提供服务',
    cal7: '(a) 本公司根据经本公司确认的委托人的具体指令，以合理的审慎和技能提供服务。若无此指令，则根据：',
    cal701: '(1) 本公司的任何标准委托单或标准规格单中的条款；和/或',
    cal702: '(2) 任何有关的贸易惯例、作法或实践；和/或',
    cal703: '(3) 本公司认为在技术、操作和/或财务方面适当的方法。',
    cal8: '(b) 结果报告中陈述的信息来源于检验/测试的结果，执行检验/测试程序是根据客户的指令，和/或根据任何技术标准，贸易惯例或实践的结果评估，或应该被考虑的在我们专业建议中的其它情况。',
    cal9: '(c) 对样品检测后出具的结果报告仅仅反映本公司对该样品的评价，不反映对被抽取样品的一批货物的评价。',
    cal10: '(d) 如客户要求本公司见证任何第三方的工作，客户同意，本公司的唯一责任是在第三方工作时出现在现场并传递该结果或证实其工作中发生的事情。客户同意，本公司对第三方使用的设备、仪器和测量器具的状况和校准、所用的分析方法、第三方人员的资格、行为或疏漏，以及分析结果均不负责。',
    cal11: '(e) 本公司出具的结果报告只反映在工作当时所记录的事实，而且限于所收到指令的范围内，若无指令时，则限于所用的本条款2(a)中给出的可选择参照的范围。本公司没有责任涉及或报告所收到的专门指令或所用的可选择参照范围以外的事实或情况。',
    cal12: '(f) 本公司可委派代理或分包商承担全部或部分服务，客户授权本公司向代理或分包商提供其所承担服务的全部必要的信息。',
    cal13: '(g) 本公司如收到涉及客户和第三方签订的契约文件或第三方的文件，如销售合同、信用证、提单等，这些文件仅供参考用，而不扩展或限制经本公司接受的服务范围或职责。',
    cal14: '(h) 客户确认，本公司在提供服务时既不取代客户或任何第三方的位置，也不免除它们应负的任何职责，此外也不承担、不削减、不免除、不承诺解除客户对任何第三方或任何第三方对客户的任何责任。',
    cal15: '(i) 所有样品的保留期最长为3个月或样品性质允许的更短期限，到期后样品退给客户或由本公司自行处理，此后本公司终止对该样品的任何责任。样品存储期超 出3个月所产生的存储费由客户支付。如样品退给客户，由客户支付退运费用。如产生样品的特殊处理费用，由客户支付。',
    cal16: '3. 客户的责任',
    cal17: '客户要：',
    cal18: '(a)保证及时提供足够的信息、指令和文件(任何情况下不得晚于所要求的工作前48小时)，以便所要求的服务得以实施；',
    cal19: '(b) 为本公司的代表取得到达工作地点的所有必要的通行权，并采取一切必要的措施，消除或纠正服务实施中遇到的任何障碍或干扰；',
    cal20: '(c) 如有要求，根据服务实施的需要提供任何特殊设备和人员；',
    cal21: '(d) 无论本公司通知要求与否，要采取一切必要的措施，确保实施服务时的工作环境、场所和装置的平安和安全；',
    cal22: '(e) 对任何委托、样品或实验中包含的任何已知的实际或潜在危险或危害，如放射性、有毒、有害或爆炸元素或物质、环境污染或中毒的存在和危险，要事先通知本公司；',
    cal23: '(f) 按照和第三方的任何有关销售合同或其它合同及法律，全面行使全部权利和清偿全部债务。',
    cal24: '4. 收费和支付',
    cal25: '(a) 在本公司接受客户委托或合同磋商时未确定收费额的，应依照本公司的标准费率(有可能调整)，并且全部应交税款由客户支付。',
    cal26: '(b) 除发票上确定了更短期限外，客户应不晚于相关发票日期起30天，或本公司在发票上确定的期限(到期日)支付全部应付给本公司的费用。如未按时付款，则要按1.5%的月息(或在发票上确定的其它利率)支付自到期日起至(包括)实际收到付款日的利息。',
    cal27: '(c) 客户无权因声称对本公司的任何争端、反诉或抵销，而留置或延迟支付应付给本公司的任何款项。',
    cal28: '(d) 本公司可决定向任何有管辖权的法院就收取未付款提出诉讼。',
    cal29: '(e) 客户应支付本公司全部的收账费用，包括律师费和有关开支。',
    cal30: '(f) 一旦在实施服务过程中出现任何不可预见的问题和费用，本公司要尽力通知客户并有权收取附加费，以弥补完成该服务必需的额外时间和开支。',
    cal31: '(g) 如果因任何超出本公司控制的原因，包括客户失于履行它在上述第3条中的任何责任，本公司不能履行全部和部分服务时，本公司依然有权收取：',
    cal3101: '(1) 本公司发生的所有不可偿还费用的总合；和',
    cal3102: '(2) 按比例支付的等于实际上已实施的服务部分的约定费用。',
    cal32: '5. 服务的暂停和终止',
    cal33: '如出现以下情况，本公司有权立即且不承担任何责任地暂停或终止提供服务：',
    cal34: '(a) 客户失于履行任何他应尽的职责，而且在通知其过失后10天内客户不作补救；',
    cal35: '(b) 客户的任何暂停付款、与债权人做出安排、破产、无力偿付、破产管理或停业。',
    cal36: '6. 责任和赔偿',
    cal37: '(a) 责任范围',
    cal3701: '(1) 本公司既不是保险商也不是担保人，不承担这方面的任何责任。客户寻求保证不损失或不损害，应该适当投保。',
    cal3702: '(2) 结果报告的出具是以客户或其代表客户提供的信息、文件和/或样品为基础，并且仅仅是为了客户的利益，而且该客户应当对其在结果报告基础上所采取的其认为合适的行为负责。对任何根据该结果报告已采取或没采取的行动，对因提供给本公司不清楚、不正确、不完全、误导或虚假信息导致的任何不正确结果，无论本公司还是公司的任何官员、雇员、代理或分包商都不应为此对客户或任何第三方承担责任。',
    cal3703: '(3) 对因任何超出本公司控制的原因，包括客户失于履行他的任何责任而直接或间接导致的任何延期、部分或全部服务不能实施，本公司不承担责任。',
    cal3704: '(4) 本公司对任何性质和不管如何产生的损失、损害或费用的任何赔偿责任，在任何情况下都不超过付给发生索赔的该项具体服务的费用总额的十倍或二万美元(或等值本国货币)这两个金额中较少的一个。',
    cal3705: '(5) 本公司不负责任何间接或其他衍生性之损失，包括且不限于利润损失、业务损失、机会损失、商誉损失及产品召回之成本。本公司亦不负责可能由客户所造成之第三者的任何损失、损害或费用之索赔(包括且不限于产品责任之索赔)。',
    cal3706: '(6) 如有任何索赔，客户必须在发现所谓证明索赔的事实起30天内书面通知本公司，并且除非在自下述之日起的一年内提起诉讼，本公司在任何情况下都被免除对损失、损害或费用的所有索赔的全部责任：',
    cal37061: 'i. 发生索赔的服务被本公司实施的日期；或',
    cal37062: 'ii. 任何声称未实施的服务应完成的日期。',
    cal38: '(b) 赔偿：客户应保证、不伤害并赔偿本公司及其官员、雇员、代理和分包商，抵偿任何第三方提出的和与实施的、声称实施的、或未实施的任何服务有关的，无论是任何性质和无论如何发生的损失、损害或费用，包括全部法律开支和有关费用的全部索赔(实际的或要发生的)。',
    cal39: '7. 其它',
    cal40: '(a) 如发现本通用条款中的某一条和几条违法或在任何方面不能执行，这绝不影响或削弱其他条款的有效性、合法性和执行性。',
    cal41: '(b) 在提供服务的过程中和其后的一年内，客户不得直接或间接诱惑、怂恿或提出聘用本公司雇员，使其离开本公司的职位。',
    cal42: '(c) 未经本公司事先书面授权，不允许以广告宣传为目的使用本公司的名称和注册商标。',
    cal43: '8. 适用法律、管辖权与争议解决',
    cal44: '除非另行约定，所有产生的或与合约双方有关的争端都要受瑞士实体法的管辖，但不包括任何冲突法，而所有的争端应按国际商会的仲裁法则，由依据该法则规定指定的一个和多个仲裁员最终裁决。仲裁应在法国巴黎，使用英语进行。',
    cal45: '9. 特别条款',
    cal46: '除上第八条所述之外，如公司和客户的注册地在中国的，所有产生的或与合约双方有关的争端都要受中国实体法的管辖，但不包括任何冲突法，而所有的争端应按上海国际仲裁中心的仲裁法则，由依据该法则规定指定的一个和多个仲裁员最终裁决。仲裁应在上海，使用中文进行。',
    newMessage: '新消息',
    messageCenter: '消息中心',
    allRead: '全部已读',
    noMore: '到底了~',
    RMB: '人民币',
    dollar: '美元'
  },
  request: {
    401: '登录信息已过期，请重新登录',
    403: '拒绝访问',
    404: '请求错误,未找到该资源',
    405: '请求方法未允许',
    408: '请求超时',
    500: '服务器端出错',
    501: '网络未实现',
    502: '网络错误',
    503: '服务不可用',
    504: '网络超时',
    505: 'http版本不支持该请求',
    linkError: '连接错误',
    timeout: '服务器响应超时，请稍后重试',
    linkServerError: '连接服务器失败'
  },
  home: {
    hello: ' للأعمال التجارية عبر الإنترنتWSCT  أهلاً! مرحبًا بكم في استخدام نظام ',
    text1: 'شركة وورلد ستاندرذيشن سيرتيفيكاشن أند تيستينج جروب الاختصار دبليو إس سي تي ، هي منصة لتوفير خدمات الفحص والاختبار الشاملة للعديد من الصناعات والمنتجات ',
    text2: 'دليل تشغيل التطبيق لشرح المصطلحات:',
    Myapplication: 'طلبي ',
    Mycertificate: 'شهادتي',
    Draftapplication: 'مسودة الطلب ',
    text3: 'الطلبات المعادة (الطلبات التي تتطلب تعديلات أو معلومات تكميلية)',
    text4: 'طلبك قيد التنفيذ',
    text5: 'شهادة سارية',
    text6: 'تنتهي صلاحية الشهادات في غضون شهر واحد',
    order: 'طلب ',
    Terminology: 'قائمة المصطلحات',
    Applicationstatus: 'حالة الطلب',
    ApplicationFormStatus: 'حالة تقديم الطلب ',
    Application1: 'في انتظار القبول :تم تقديم النموذج ، ولكن لم يتم قبوله  ',
    Application2: 'تم إرجاع الطلب :تم الإرجاع ، تحتاج إلي تعديل معلومات الطلب أو الملف الملحق',
    Application3: 'المعالجة: قيد التنفيذ ، عملية التنفيذ طبيعية',
    Application4: 'تم الإصدار: تم إصدار الشهادة وانتهت العملية',
    Application5: 'تم الإلغاء: تم إلغاء الإقرار',
    Paymentstatus: 'حالة الدفع',
    Payment1: 'عرض الأسعار معلق: تم قبول الطلب  ، ولكن الطرف الذي تم قبول طلبك لم يقم بارسال عرض الاسعار ',
    Payment2: 'في انتظارالدفع: تم تقديم عرض الأسعار ، لكن العميل لم يدفع بعد (بما في ذلك التحويل في البنك ، والذي لم يتم تأكيده من قبل الشؤون المالية للطرف المقبول)',
    Payment3: 'السداد الجزئي: بعد الدفع، يوجد تناقض بين مبلغ التأكيد المالي للطرف الذي قبله ومبلغ الطلب ',
    Payment4: 'تم إكمال عملية الدفع : تم التسديد بالكامل ',
    ApplicationStage: 'مرحلة الدفع',
    stage1: 'مرحلة التقديم علي شهادة النظام',
    stage2: 'مرحلة التقديم لشهادة التخليص الجمركي',
    stage3: 'مرحلة طلب التفتيش  ',
    PendingApplications: 'الطلبات المعلقة',
    CertificationAcceptance: 'قبول الشهادات',
    Typetest: 'نوع الاختبار',
    FactoryInspection: 'فحص المصنع',
    ConformityAssessment: 'تقييم المطابقة',
    CertificateIssuance: 'إصدار الشهادة',
    CertificateProduction: 'عمل الشهادة ',
    EndCertification: 'الانتهاء من الشهادة',
    FieldInspection: 'التفتيش الميداني',
    LaboratoryTesting: 'الاختبارات المعملية'

  },
  myExpenses: {
    address: 'العنوان',
    name: 'الاسم',
    date: 'التاريخ'
  },
  login: {
    AcountLogin: 'تسجيل الدخول',
    MobileLogin: 'رقم هاتف تسجيل الدخول',
    signIn: 'تسجيل الدخول',
    register: 'تسجيل',
    Forgetpassword: 'نسيت كلمة المرور',
    userPlaceholder: 'اسم المستخدم',
    userError: 'الرجاء إدخال اسم المستخدم',
    PWPlaceholder: 'الرجاء إدخال كلمة المرور',
    PWError: 'الرجاء إدخال كلمة المرور',
    PWnum: 'يجب أن تتكون كلمة المرور من 6 أحرف على الأقل',
    Codelaceholder: 'الرجاء إدخال الرقم التأكيدي',
    CodeError: 'الرجاء إدخال الرقم التأكيدي',
    errorcode: 'خطأ في الرقم التأكيدي',
    mobilePlaceholder: 'رقم الهاتف المحمول ',
    mobileError: 'الرجاء إدخال رقم الهاتف المحمول',
    mobilenum: 'أدخل رقم الهاتف المحمول المكون من 11 رقمًا',
    smsPlaceholder: 'رمز التحقق عبر الرسائل القصيرة',
    smsError: '"الرجاء إدخال رقم التحقق',
    smsGet: 'الحصول على رمز التحقق',
    smsSent: 'تم إرسال رسالة نصية إلى رقم الهاتف المحمول',
    noAccount: 'ليس لديك حساب بعد؟',
    createAccount: 'إنشاء حساب جديد',
    wechatLoginTitle: 'قم بمسح الكود للدخول',
    wechatLoginMsg: 'من فضلك قم بمسح الكود باستخدام مؤسسة الويتشات وتسجيل الدخول ',
    wechatLoginResult: 'قم بالمسح / الرجاء النقر فوق الجهاز للسماح بتسجيل الدخول',
    logsuccess: 'تم تسجيل الدخول بنجاح',
    mosuccess: 'تم التعديل بنجاح',
    wholeError: 'الرجاء إدخال رقم هاتفك أو عنوان بريدك الإلكتروني'
  },
  myProfile: {
    username: 'اسم المستخدم',
    headimg: 'صورة المستخدم',
    name: 'الاسم',
    sex: 'الجنس',
    customerIds: 'رقم هوية العميل',
    mobile: 'رقم الهاتف المحمول',
    email: 'البريد الالكتروني',
    WeChatOrQQ: 'رقم الويتشات',
    selectCountry: 'اختر الدولة',
    selectProvinceCityRegion: 'اختر المحافظة والمدينة',
    addressDetail: 'العنوان بالتفصيل',
    english: '(إنجليزي)',
    postcode: 'الرمز البريدي',
    companyName: 'اسم الشركة',
    businessLicense: 'السجل التجاري',
    corporate: 'الممثل القانوني للشركة',
    companyPhone: 'رقم الشركة',
    companyFax: 'فاكس الشركة',
    remark: 'ملحوظة',
    man: 'ذكر',
    woman: 'أنثي',
    Bailor: 'الموكل',
    Manufacturer: 'المصنع',
    Factory: 'مصنع الانتاج',
    Agency: 'المندوب /الوكيل',
    editMyProfile: 'تعديل ملفي الشخصي',
    uploadFile: 'قم بسحب الملف وإفلاته في المنطقة أو انقر للتحميل',
    uploadFormat: 'تحميل شكل JPG / JPEG / بابوا نيو غينيا / GIF / BMP / قوات الدفاع الشعبي / docx / doc / xls / xlsx ، حجم لا يتجاوز 20 ميغابايت ، تحميل ما يصل إلى 5 ملفات',
    imgType: 'يجب أن تكون الصورة بتنسيق جي بي جي ، أو جي أي اف أو بي إن جي',
    imgSize: 'حجم الصورة لا يمكن أن يتجاوز 1 ميغا بايت',
    licenseType: ' jpg / png / jpeg / gif / pdfيجب أن يكون الملف بتنسيق',
    licenseLength: '营业执照最多上传五个文件',
    licenseSize: 'لا يمكن أن يتجاوز حجم الملف 3 ميغا بايت',
    validateUserName: 'الرجاء إدخال اسم تسجيل الدخول',
    validateUserNameLength: '"طول" اسم تسجيل الدخول "هو 2 ~ 16 حرفًا',
    validateUserNameFormat: '"يجب أن يكون "اسم تسجيل الدخول" رقمًا أو باللغة الإنجليزية',
    validateName: 'الرجاء إدخال اسمك الحقيقي',
    validateNameLength: '"الاسم الحقيقي" 2 ~ 100 حرفًا',
    validateNameFormat: '"يجب أن يكون "الاسم الحقيقي" صينيًا أو إنجليزيًا',
    validateMobile: 'الرجاء إدخال رقم هاتفك المحمول',
    validateMobileLength: '"طول" رقم الهاتف المحمول "هو 8 ~ 30 حرفًا',
    validateMobileFormat: 'الرجاء إدخال "رقم الهاتف المحمول" الصحيح"',
    validatePass: 'الرجاء إدخال كلمة مرور',
    validatePassLength: '"طول" كلمة المرور "هو 8 ~ 12 حرفًا',
    // validatePassFormat: '"؛ <=! "# $٪ & \ '() * +@ [] ^ _` {|} ~،？ -. / :> يجب أن تحتوي "كلمة المرور" على أرقام وأحرف ورموز خاصة',
    validateEmail: 'الرجاء إدخال بريدك الإلكتروني',
    validateEmailFormat: 'الرجاء إدخال تنسيق البريد الإلكتروني الصحيح',
    validateZipCode: 'الرجاء إدخال الرمز البريدي',
    validateZipCodeFormat: '请输入4-6位数字的邮政编码',
    validateSex: 'الرجاء إدخال الجنس',
    validateIdentity: 'الرجاء إدخال هوية العميل',
    validateCountry: 'الرجاء تحديد بلد',
    validateProvince: 'الرجاء تحديد المحافظة أو المدينة',
    validateAddress: 'الرجاء إدخال تفاصيل العنوان',
    validateCompany: 'الرجاء إدخال اسم الشركة',
    validateCompanyLegal: 'الرجاء إدخال الممثل القانوني للشركة',
    validateLicense: 'الرجاء إرفاق الرخصة التجارية',
    completeMyInfo: '请完善账号信息内容'
  },
  register: {
    Accountname: 'اسم الحساب',
    Accountlaceholder: 'الرجاء إدخال اسم',
    nameError: 'الرجاء إدخال اسم',
    Password: 'كلمة المرور',
    PWPlaceholder: 'الرجاء إدخال كلمة المرور',
    PWError: 'الرجاء إدخال كلمة المرور',
    Passwords: 'تأكيد كلمة المرور',
    PWPlaceholders: 'الرجاء إدخال كلمة المرور مرة أخرى',
    PWErrors: 'الرجاء إدخال كلمة المرور مرة أخرى',
    inconsistent: '"كلمتا مرور إدخال غير متطابقتين',
    Name: 'الاسم الحقيقي',
    Namelaceholder: 'الرجاء إدخال اسمك الحقيقي',
    NameError: 'الرجاء إدخال اسمك الحقيقي',
    mobile: 'رقم الهاتف المحمول',
    mobilePlaceholder: 'الرجاء إدخال رقم هاتفك المحمول',
    mobileError: 'الرجاء إدخال رقم هاتفك المحمول',
    email: 'البريد الإلكتروني',
    emailPlaceholder: 'الرجاء إدخال بريدك الإلكتروني',
    emailError: 'الرجاء إدخال بريدك الإلكتروني',
    read: 'لقد قرأت ووافقت',
    readError: 'يرجى قراءة الاتفاقية والموافقة عليها',
    agreement: '《اتفاقية خدمة المنصة》',
    Backlogin: 'الرجوع إلى تسجيل الدخول',
    register: 'تسجيل',
    Resuccess: 'تم التسجيل بنجاح'
  },
  setting: {
    oldPassword: 'كلمة المرور القديمة',
    oldPWPlaceholder: 'الرجاء إدخال كلمة المرور القديمة',
    oldPWError: 'الرجاء إدخال كلمة المرور القديمة',
    Password: 'كلمة المرور',
    PWPlaceholder: 'الرجاء إدخال كلمة المرور',
    PWError: 'الرجاء إدخال كلمة المرور',
    Passwords: 'تأكيد كلمة المرور',
    PWPlaceholders: 'الرجاء إدخال كلمة المرور مرة أخرى',
    PWErrors: 'الرجاء إدخال كلمة المرور مرة أخرى',
    inconsistent: 'كلمتا مرور إدخال غير متسقتين',
    changePassword: 'تغيير كلمة المرور',
    Modificationsuccess: 'نجح التعديل'
  },
  // حالة الطلب
  rfclist: {
    Number: 'رقم التسلسل',
    HSCode: 'رقم الرمز الجمركى',
    ApplicationFor: 'نوع الشهادة',
    Title: ' العنوان',
    TitlePlaceholder: 'الرجاء إدخال عنوان',
    Importer: 'المستورد',
    ImporterPlaceholder: 'الرجاء إدخال نائب المستورد',
    Exporter: 'المصدر',
    ExporterPlaceholder: 'الرجاء إدخال نائب المصدر',
    Status: 'الحالة',
    CreatedTime: 'وقت الإنشاء',
    PaymentStatus: 'حالة السداد(الدفع)',
    Quotation: 'قائمة الأسعار',
    QuotationPreview: 'معاينة السعر',
    QuotationConfirmation: 'تأكيد السعر',
    PlaceOfInspection: 'عنوان الفحص',
    RFCNo: 'رقم الحاوية',
    DraftCertificate: 'مسودة الشهادة',
    DraftPreview: 'معاينة المسودة',
    DraftReview: 'تأكيد المسودة ',
    OthersAction: 'عمليات أخرى',
    Keywords: 'كلمات المفتاح',
    KeywordsPlaceholder: 'الرجاء إدخال كلمة المفتاح',
    Country: 'الدولة',
    ViewQuotation: 'فحص قائمة السعر',
    Preview: 'معاينة',
    Totalamount: 'المبلغ الإجمالي',
    AmountPaid: 'مدفوع',
    Unpaid: 'غير مدفوعة',
    AccepterEmail: 'االبريد الإلكترونى للمستلم',
    DraftConfirmation: 'تأكيد المسودة',
    DraftView: 'فحص المسودة'
  },
  // شهادتي
  certificate: {
    Number: 'رقم التسلسل',
    RFCNo: 'رقم الحاوية.',
    CertificateNo: 'رقم الشهادة',
    Title: 'العنوان',
    Importer: 'المستورد',
    Exporter: 'المصدر',
    CertificateStatus: 'حالة الشهادة',
    ApplicationFor: 'نوع الطلب',
    IssueDate: 'تاريخ الإصدار',
    Route: 'Route',
    CreatedTime: 'تاريخ الإنشاء',
    CertificateType: 'نوع الشهادة',
    Action: 'العمل',
    Status: 'الحالة',
    CertificateDetails: 'تفاصيل الشهادة',
    CompanyName: 'اسم الشركة'
  },
  customsClearance: {
    applicationDrafts: {
      title: 'العنوان',
      exporter: 'المصدر',
      applicationFor: 'نوع الطلب',
      importer: 'المستورد',
      placeOfInspection: 'مكان الفحص',
      createdTime: 'تاريخ الإنشاء',
      certificateType: '证书类型'
    },
    myApplicationForm: {
      Select: 'إختر الأعمال المتطابقة',
      country: 'الدولة',
      createMode: 'طريقة الإنشاء',
      createForm: 'إنشاء الشكل',
      importApplicationData: 'إدخال بيانات طلب الإستيراد',
      choose: 'إختر',
      pleaseChoose: 'الرجاء النقر فوق الزر للتحديد',
      title: 'العنوان',
      applicationFor: 'نوع الطلب',
      hasIDFNumber: 'هل تم الحصول على رقم نموذج إقرار الاستيراد؟',
      IDFNo: 'نموذج إقرار الاستيراد (IDF)',
      UcrNo: 'الرقم المرجعي للشحنة',
      mailCertificateCollection: 'استلام شهادة البريد الإلكتروني',
      shipmentMethod: 'طريقة الشحن',
      cargoType: 'طريقة الشحن',
      containerInfo: 'معلومات الحاوية',
      placeOfLoading: 'ميناء التحميل',
      placeOfDischarge: 'ميناء التفريغ',
      countryOfSupply: 'بلد المورد',
      countryOfOrigin: 'بلد الشحن',
      quantityDelivered: 'الكمية التى تم إستلامها',
      full: 'كامل',
      partial: 'جزئى',
      tel: 'الهاتف',
      fax: 'الفاكس',
      qq: 'QQ',
      email: 'البريد الإلكترونى',
      hotline: 'الهاتف المحمول الرئيسي للخط الساخن للعميل / WeChat',
      address: 'العنوان',
      contactInfo: 'إذا كنت بحاجة إلى التعامل مع هذا العمل ، فيرجى الاتصال بنا من خلال الطرق التالية',
      addressDetail: 'مقاطعة جواندونغ الصينية ، مدينة شينجن ، منطقة باوان ، طريق باوشى ، حديقة باوشى للعلوم والتكنولوجيا المبنى أ- ب'
    },
    productConformity: {
      placeOrdersOnline: 'قم بعمل الطلب أون لاين ، وإدراته بسرعة',
      draftsDetailTitle: 'تفاصيل مسودة الطلب',
      statusDetailTitle: 'تفاصيل حالة عمل الطلب',
      businessGuide: 'دليل تقديم الطلبات',
      importantNote: ' ملاحظة مهمة: يجب إكمال هذا النموذج وتقديمه إلى دبليو إس تى قبل الشحن',
      FillOutTheFormOnlineHint: 'املأ النموذج أون لاين أو نقل البيانات التاريخية أو قم بتنزيل النموذج لملئه ثم إرساله',
      basicContentsForm: 'المحتوى الأساسي لاستمارة طلب التفتيش',
      uploadAttachmentAndInvoice: 'قم بتحميل الفاتورة الأولية والمرفقات المطلوبة لاستمارة طلب الفحص',
      uploadAttachmentAndInvoice2: ' الفاتورة الأولية والمرفقات المطلوبة لاستمارة طلب الفحص',
      uploadAttachment: 'قم بتحميل المرفقات المطلوبة لاستمارة طلب الفحص',
      uploadAttachment2: 'المرفقات المطلوبة لاستمارة طلب التفتيش',
      pleaseEnterParties: ' الرجاء إدخال معلومات كل طرف تجاري',
      pleaseEnterParties2: ' معلومات عن كل طرف تجاري',
      finalInvoice: 'الفاتورة النهائية أو الفاتورة المبدئية',
      itemsSending: 'أضف معلومات المنتج المشحون (الإعلان الكامل لجميع معلومات المنتج)',
      itemsSending2: '出货产品的信息（完整申报所有产品信息）',
      createType: '创建类型',
      selectFormTypeTitle: '参考信息',
      applicationFormTitle: '填写申请表基本内容',
      titlePrompt: '就是您给自己的申请起的名字，便于您查询和区分不同的申请，两个申请的申请标题不能重复。 您可以采用便于自己统计和查询的方式命名，不宜太长，举例： 1）公司名称缩写-产品缩写-编号；2） 商标-日期-编号 。',
      applicationFormTitle2: '申请表基本内容',
      UploadTitle: 'قم بتحميل الفاتورة الأولية التي ينتمي إليها نموذج طلب الفحص',
      downloadTemplate: '下载模板',
      import: ' إستيراد ',
      saveDraft: 'حفظ كمسود',
      addInvoice: ' أضف الفاتورة',
      invoiceText: 'نص الفاتورة',
      invoiceRemind: 'يجب إضافة معلومات فاتورة واحدة على الأقل',
      upload: 'تحميل',
      invoiceFile: 'ملف لفاورة',
      invoiceNO: 'رقم الفاتورة',
      invoiceDate: 'تاريخ الفاتورة',
      currency: 'نوع العملة',
      amount: 'المبلغ الإجمالى',
      incoterm: 'مصطلح التجارة الدولى',
      uploadText: 'انقر أو اسحب الملف',
      upload2: 'تحميل',
      uploadTip: 'تنسيق التحميل هو jpg / gif / png / bmp / ​​doc / docx / xlsx / xls / pdf / txt / zip / tar والحجم لا يتجاوز 30 ميجا بايت',
      uploadDocument: 'تحميل المرفق',
      documentText: 'المرفق',
      documentTip: 'قائمة التعبئة / وثائق الستيراد / وثائق الجودة / وثائق أخرى',
      documentMessage01: '1、يجب ألا يتجاوز حجم كل مرفق تم تحميله 30 ميجا بايت ؛ وإلا فلن يكون من الممكن تقديمه بشكل طبيعي.',
      documentMessage02: '2、أنواع الملفات المدعومة:jpg، gif، png، bmp، doc، docx، xlsx، xls، pdf، txt، zip، tar.',
      fileType: 'نوع الملف',
      fileName: 'اسم الملف ',
      size: 'الحجم',
      remarks: 'ملحوظات',
      companyName: 'اسم الشركة',
      companyNameEn: 'اسم الشركة (انجليزي)',
      country: 'الدولة',
      city: 'المدينة',
      address: 'العنوان',
      zipCode: 'الرمز البريدى',
      contactName: 'اسم جهة الاتصال',
      telephone: 'رقم الهاتف',
      telephonePlaceholder: '示例：+86-10-8888888 或 86-010888888',
      email: 'البريد الإلكترونى',
      fax: 'الفاكس',
      payWSCTService: 'دفع مقابل خدمات WSCT',
      exporterDetails: 'تفاصيل البائع / بلد التصدير',
      placeOfInspection: 'مكان الفحص',
      chooseHistoricalData: 'إختر البيانات التاريخية',
      importerDetails: 'تفاصيل المستورد ',
      warehouseName: 'اسم المستودع',
      warehouseNameEn: 'اسم المستودع (انجليزي)',
      zoneProvince: 'المنطقة / المحافظة',
      GoodsAvailableDate: 'تاريخ الفحص المقدر',
      pleaseSelectRfcType: 'الرجاء تحديد نوع الطلب أولا',
      beneficiary: '受益方',
      addProduct: 'أضف المنتج',
      HSCode: 'الرمز الجمركى',
      brand: 'الماركة',
      modelNumber: 'رقم الموديل',
      quantity: 'الكمية',
      unitPrice: 'سعر الوحدة',
      unit: 'الوحدة',
      goodsDescription: 'وصف المنتج',
      manufacturerName: 'اسم المصنع',
      manufacturerAddress: 'عنوان الشركة المصنعة',
      countryOfOrigin: 'بلد المنشأ',
      goodsCondition: 'حالة الشحن',
      assemblyState: 'حالة الشحنة',
      ApplicableStandard: 'المعايير المناسبة للتطبيق',
      brOfRegistration: 'الشهادة متاحة',
      correspondingCertificate: '对应注册证书（SOR）/许可证书（SOL）/产品证书（PC）号码',
      ClearAllItems: 'امسح كل المعلومات',
      getTemplate: 'تحميل النموذج',
      importItems: 'بيانات الاستيراد',
      productList: 'قائمة المنتجات',
      uploadProductTip: 'تنسيق ملف البيانات الذي تم استيراده ه xlsx ولا يتجاوز الحجم 20 ميغا بايت',
      containerSize: 'حجم الحاويات',
      containerQuantity: 'كمية الحاوية',
      applyTypeItem: 'الرجاء تحديد نوع الطلب',
      getIdfNo: 'يرجى تحديد ما إذا كنت قد حصلت على رقم نموذج إقرار الإستيراد أم لا',
      idfNo: 'برجاءإدخال رقم نموذج إقرار الاستيراد',
      ucrNo: 'برجاء إدخال الرقم المرجعى للشحنة',
      needCertPerson: 'الرجاء اختيار شهادة بريد لتلقيها',
      transportMode: 'الرجاء تحديد طريقة الشحن',
      packingMethod: 'الرجاء تحديد طريقة الشحن',
      containerData: 'الرجاء إدخال معلومات الحاوية',
      supplierCountry: 'الرجاء تحديد بلد التوريد',
      selectCorrectData: '请选择正确的',
      // معلومات الفاتورة
      invoicePath: 'الرجاء تحميل الفاتورة',
      invoiceNo: 'الرجاء إدخال رقم الفاتورة',
      selectInvoiceDate: 'الرجاء تحديد تاريخ الفاتورة',
      selectTradeTerm: 'الرجاء اختيار المصطلح التجارى الدولى',
      selectCurrency: 'الرجاء تحديد العملة',
      invoiceTotal: 'الرجاء إدخال المبلغ الإجمالي',
      // 附件信息
      selectFileType: '请选择文件类型',
      pleaseUploadFile: '请上传附件',
      // المعلومات بالتفاصيل
      inputCompanyName: 'الرجاء إدخال اسم الشركة',
      inputCompanyNameEn: '请输入公司名称（英文）',
      inputWarehousename: 'الرجاء إدخال اسم المستودع',
      inputWarehousenameEn: 'الرجاء ادخال اسم المستودع (انجليزي)',
      countryName: 'الرجاء إدخال الدولة',
      cityName: 'الرجاء إدخال اسم المدينة',
      inputAddress: 'الرجاء إدخال العنوان',
      inputContact: 'الرجاء إدخال جهة اتصال',
      inputTelephone: 'يرجى إدخال رقم الهاتف الخاص بك',
      selectPostDate: 'الرجاء تحديد تاريخ الفحص المتوقع',
      inputEmail: 'الرجاء إدخال البريد الإلكتروني الخاص بك',
      inputEmailReg: 'الرجاء إدخال تنسيق البريد الإلكتروني الصحيح',
      inputTelephoneReg: '格式为4-20位的数字、特殊字符+-',
      inputRegionOrPrice: 'الرجاء إدخال المنطقة / السعر',
      // معلومات المنتج
      inputHSCode: 'الرجاء إدخال الرمز الجمركي',
      inputBrandName: 'الرجاء إدخال العلامة التجارية',
      inputModel: 'الرجاء إدخال رقم الموديل',
      inputQuantity: 'الرجاء إدخال الكمية',
      inputPrice: 'الرجاء إدخال سعر الوحدة',
      selectUnit: 'الرجاء إختر الوحدة',
      inputGoodsDescription: 'الرجاء إدخال وصف المنتج',
      inputManufacturerName: 'الرجاء إدخال اسم الشركة المصنعة',
      inputManufacturerAddress: 'الرجاء إدخال عنوان الشركة المصنعة',
      selectOriginCountry: 'الرجاء تحديد بلد المنشأ',
      selectFreightStatus: 'الرجاء تحديد حالة البضاعة',
      selectAssembleStatus: 'الرجاء تحديد حالة الحاوية',
      selectHistory: 'إختر بيانات التطبيق الخارجية',
      importExcel: 'تنسيق التحميل هو xlsx ولا يتجاوز الحجم 20 ميجا بايت',
      noCountryText: 'لم يتم العثور على معلومات الدولة',
      applicationDonotEdit: 'لا يمكن تعديل حالة طلب الشهادة الحالي ،، يرجى الاتصال بمسؤول التشغيل',
      addApplicationSaveSuccess: 'نجح الحفظ التلقائي',
      addApplicationSaveError: 'فشل الحفظ التلقائي',
      addApplicationSubmitSuccess: 'تم الإرسال بنجاح',
      addApplicationSubmitError: 'فشل الإستلام',
      addApplicationDraftsSuccess: 'حفظ المسودة بنجاح',
      addApplicationDraftsError: 'فشل حفظ المسودة',
      completeApplicationContent: 'يرجى استكمال المحتوى المطلوب لطلب الشهادة',
      lessAddOneProduct: 'أضف عنصر منتج واحد على الأقل',
      pleaseAddInvoice: 'الرجاء إضافة معلومات الفاتورة',
      improvedocumentInfo: 'الرجاء إدخال المعلومات المطلوبة للمرفق',
      upload20Attachments: 'تذكير: يمكن تحميل ما يصل إلى عشرين مرفقًا！',
      selectHistoryData: 'الرجاء تحديد بيانات التطبيق التاريخية',
      selectApplyType: '请选择申请类型',
      pleaseUpdateTemplate: 'الرجاء تحميل النموذج',
      whetherInspect: 'هل التفتيش عن بعد أم لا',
      pleaseSaveOrEdit: 'يرجى حفظ أو حذف معلومات المرفق في حالة التعديل',
      maxInvoiceText: 'أقصى حد لتحميل معلومات الفاتورة هو ثلاث ملفات',
      currentApplicationCannotEdit: 'لا يمكن تعديل حالة طلب الشهادة الحالي ، يرجى الاتصال بموظف التشغيل',
      timeCannotEarlier: 'لا يمكن أن يكون الوقت قبل التاريخ الحالي',
      timeCannotToday: '时间不能超过当前日期',
      pleaseImproveProduct: 'يرجى استكمال معلومات المنتج：',
      limitFilesNum: 'يمكنك فقط تحميل ما يصل إلى 5 مرفقات في المرة الواحدة',
      sureSaveDraft: '确定存入草稿吗？',
      uploadSuccess: '上传成功',
      uploadError: '上传失败',
      toModify: '去修改',
      haveFailDocument: '个附件正在上传或上传失败，是否确定提交？',
      reupload: '重新上传',
      excelImport: 'Excel导入',
      // معلومات النقل
      packagingInformation: 'معلومات التغليف',
      packageQuantity: 'كمية التعبئة',
      transportContainerNumber: 'رقم حاوية الشحن',
      acid: 'ACID',
      importersTaxNumber: 'الرقم الضريبى للمستورد',
      exporterID: 'بطاقة هوية المصدر',
      exportCountry: 'بلد التصدير',
      exportCountryCode: 'كود بلد التصدير',
      limitContainerNo: '最多只能添加10个运输柜号',
      limitContainerNoItem: '运输柜号为字母和数字并且长度不超过20',
      pleaseInputTransportNum: '请输入运输柜号',
      pleaseInputacid: '请输入ACID',
      pleaseSelectpackaging: '请选择包装信息',
      pleaseInputPackageNum: '请输入包装数量',
      pleaseInputImportersTax: '请输入进口商税号',
      pleaseInputExportID: '请输入出口商ID',
      pleaseSelectExportCode: '请选择出口国家代号',
      inputAfterEnter: '输入后按Enter键添加',
      // 加载
      loading: '加载中...',
      submitting: '提交中...',
      uploading: '上传中...',
      // 用户条款
      userTerms: '用户条款',
      companyAddress: '，地址',
      ourCompany: '我司，',
      userTermsStated: '，在此特别申明，本次申报的产品不受召回产品的影响，也不是不合格品或假冒伪劣产品，我们对所有申报信息的真实性承担责任。 同时，我们也深知进口国对这些产品进口的监管要求并承诺遵守这些要求。',
      userTermsInfo: '提交信息后，WSCT会尽快受理申请单并且邮件联系您，请确认是否此邮箱',
      usreTermsInfo2: '能联系到您，如不是，请在下方填写，多个邮箱以“；”隔开。'
    },
    applicationSuccess: {
      returnIndex: 'الرجوع للصفحة الرئيسية ',
      submittedForReview: 'تمت المراجعة',
      reapply: 'قم بتقديم الطلب مرة أخرى',
      viewApplicationRecord: 'عرض تاريخ التطبيق'
    },
    exportProductCertification: {
      mailCertificateCollection: 'استلام شهادة البريد الإلكتروني',
      businessInfo: 'معلومات التاجر',
      importer: 'المستورد',
      exporter: 'المصدر',
      manufacturer: 'المصنع',
      placeOfSurvey: 'مكان المسح',
      VATNumber: 'رقم ضريبة القيمة المضافة',
      paymentInfo: 'معلومات الدفع',
      transportInfo: 'معلومات النقل',
      dateOfShipment: 'تاريخ الشحنة',
      loadingType: 'نوع التحميل',
      loadingPort: 'ميناء التحميل',
      dischargingPort: 'ميناء التفريغ',
      productInvoice: 'فاتورة المنتج',
      uploadProductInvoice: 'تحميل فاتورة المنتج',
      invoiceInfo: 'معلومات الفاتورة（الفاتورة النهائية أو الفاتورة المبدئية）',
      noData: 'لا يوجد بيانات',
      accessoriesOfAProduct: 'ملحقات المنتج',
      updateAccessoriesOfAProduct: 'تحديث ملحقات المنتج',
      saveToDraft: 'حفظ المسودة',
      oneClickTop: 'نقرة واحدة أعلى'
    }
  },
  particular: {
    dwarTitle: 'شهادة التصدير وشهادة المنتج',
    businessIntroduction: 'مقدمة الأعمال',
    exportcertification: 'شهادة التصدير',
    text1: 'اختبار شهادة التخليص الجمركي للتصدير',
    text2: 'شهادة التصدير هي شهادة المنتجات المتطابقة。وذلك من أجل ضمان جودة المنتجات المستوردة，حيث تواصل المزيد من البلدان إدخال القوانين واللوائح المتعلقة بتحديث المنتجات المستوردة ، والتى يتطلب من المستوردين والمصدرين بتفويض وكالة تفتيش وإصدار شهادات خارجية لفحص المنتجات قبل الشحن ، إصدار معلومات عن المنتجات المطابقة لمواصفات الدولة المستوردة.جعل البضائع تمر عبر الجمارك بسلاسة في البلد المستورد ، وإلا فلا يمكن استيرادها。',
    text3: 'لذلك ، بدأ عدد أكبر من البلدان في تنفيذ "خطط تقييم المطابقة" (مثل PVoC و CoC وشهادات التخليص الجمركي الأخرى) كجزء من خطط التنمية المستدامة. الشهادات التي يصدرونها لها أسماء مختلفة قليلاً ، لكنها في الواقع متشابهة إلى حد كبير. يكفي فهمها على أنها تخليص جمركي. على سبيل المثال ، كينيا (شهادة التخليص الجمركي) ، بوتسوانا (شهادة التخليص الجمركي) ، مصر (تسمى شهادة التفتيش) ، نيجيريا (تسمى شهادة SONCAP ، أو SC) ، أوغندا (التخليص الجمركي).',
    text4: 'ستعتمد الخدمات التجارية والمؤسسية فى دبليو إس تى على فريق محترف وخبرة غنية في تقييم مطابقة المنتج للمساعدة وتقديم حلول شاملة وفعالة ومصممة خصيصًا لشركات التصدير لضمان امتثال سلع المصدر للمعايير واللوائح المعمول بها لمتطلبات البلد المستورد ، لمساعدة منتجاتك على التخليص الجمركي بسلاسة.',
    mainProcess: 'العملية الرئيسية',
    text5: 'توفير المعلومات لهيئات التفتيش وإصدار الشهادات(مثل دبليو إس تى) للمراجعة وحجز عمليات التفتيش.',
    text6: 'فيما يتعلق بالمعلومات المحددة المقدمة ، قد تكون هناك بعض الاختلافات بين البلدان المختلفة والبلدان المصدرة ، لكن الوضع العام متشابه. على سبيل المثال: التصدير إلى نيجيريا: توكيل رسمي (توفر الوكالة نموذجًا للعملاء لملئه) ، قائمة التعبئة ، تقرير الاختبار (ما هي المعايير المقبولة لتقرير الاختبار المحدد ، وكيفية تحديد فترة صلاحية التقرير باستخدام الوكالة) والنماذج ؛ التصدير إلى كينيا: توكيل رسمي (مقدم من الوكالة إلى النموذج الذي يملأه العميل) ، وقائمة التعبئة ، وتقرير الاختبار (ما هي المعايير المقبولة لتقرير الاختبار المحدد ، وكيفية تحديد فترة صلاحية التقرير مع المؤسسة)、نموذج إقرار الاستيردا。',
    text7: 'حدد موعدًا للتفتيش عند الموافقة.',
    text8: 'بعد إنتاج البضائع وتعبئتها ، يمكن للمفتشين فحص البضائع. خلال هذه العملية ، سيقوم المفتش بفحص عبوة المنتج ، وفتح عدة صناديق بشكل عشوائي لفحص المنتجات بالداخل ، والتقاط الصور وإرسال تقرير الفحص مرة أخرى إلى الشركة للمراجعة.',
    text9: 'إصدار التخليص',
    text10: 'بعد الفحص ، إذا كانت هناك اقتراحات للتصحيح ، يجب على المصدر إجراء التصحيح قبل الشحن. في حالة عدم وجود رأي تصحيحي ، يقوم المصدر بإصداره للتحقق من قبل المصدر. في حالة عدم وجود خطأ ، يجب على المصدر إرسال الأصل إلى المرسل إليه في ميناء الوصول للتخليص الجمركي.'
  },
  policyInfo: {
    policy: '政策法规'
  },
  messageList: {
    title: '标题',
    NoticeType: '消息类型',
    Readornot: '是否已读',
    Date: '日期',
    Messagedetails: '消息详情',
    center: '消息中心',
    Acceptor: '受理人：',
    Data: '日期：',
    Read: '已读',
    Unread: '未读',
    more: '更多'
  },
  publicFile: {
    title: '公开文件下载列表'
  },
  entrustedInspection: {
    contactPhone: '联系电话',
    checkAddress: '检验地址',
    contractNo: '合同编号',
    countryCodeOfProduct: '产品销售国代码',
    inputAddress: '请输入详细地址',
    verificationDecription: '检验描述',
    inputDescription: '请输入检验描述',
    selectCountryCode: '请选择产品销售国代码',
    selectStandard: '请选择标准',
    choiceCriterion: '选择标准',
    PreInspectionDate: '预检日期',
    entrustedBaseInfo: ' 填写委托检验方基本信息',
    entrustedBaseInfo2: ' 委托检验方基本信息',
    inspectionServices: '检验服务',
    pleaseSelectServer: '选择服务',
    viewContract: '查看委托检验合同'
  },
  formLength: {
    maxLength255: '最大长度为255字符',
    maxLength500: '最大长度为500字符',
    maxLength100: '最大长度为100字符'
  },
  entrustedBusiness: {
    entrustedInspection: '委托检验',
    entrustedDescribe: '委托检验是指企业为了对其生产、销售的产品质量监督和判定，委托具有法定检验资格的检验机构进行检验。检验机构依据标准或合同约定对产品检验，出具检验报告给委托人，检验结果一般仅对来样负责。',
    entrustedDescribeTwo: 'WSCT贸易及机构服务将凭借专业的团队，丰富的产品符合性评定经验，协助并为广大出口企业提供广泛、高效和量身定制的解决方案，以确保出口商的货物符合进口国适用的标准和法规要求, 助您的产品顺利完成委托检验。',
    mainProcesses: '主要流程',
    processessOne: '向检验和认证机构(如WSCT)提供信息以供审查并预约检验。',
    processessOneDescribe: '就提供的具体信息而言，不同国家和出口国之间可能有一些差异，但总体上是相似的。例如:出口尼日利亚:委托书(机构提供模板供客户填写)、装箱单、、检测报告(具体检测报告接受什么标准，如何与机构确定报告有效期)、表格；出口肯尼亚:委托书(机构提供给客户填写的模板)、装箱单、、检测报告(具体检测报告接受什么标准，如何与机构确定报告有效期)、IDF。',
    processessTwo: '业务受理。',
    processessTwoDescribe: '确认协议书内容，样品状态，签字，划价，财务收费，开具发票，等信息，前往指定的地址依据协议书中签订的内容进行检验委托报告签发。',
    processessThree: '检验完成后，由相关的检验部门编制检验报告，经审核后，提交批准人签发，用户可以在WSCT客户端中下载电子版的报告，需要邮寄的，业务管理部门可以代办邮递手续；如对报告有异议的及时联系WSCT相关业务部门，我们会及时给予处理。'
  },
  // 通知
  notice: {
    // 送样通知
    serialNumber: '申请流水号',
    certificationCategory: '认证类别',
    sendingTime: '发送时间',
    notificationNumber: '通知编号',
    applicationNo: '申请编号',
    applicationTitle: '申请标题',
    applicationCategory: '申请类别',
    bailor: '委托人',
    factory: '生产厂',
    productName: '产品名称',
    sender: '发送人',
    sendingDepartment: '发送部门',
    currentStage: '当前阶段',
    // 收样回执通知
    title: '标题',
    read: '是否已读',
    institutionOrdepartment: '发送机构/部门',
    type: '类型'
  },
  // 缴费记录
  paymentRecord: {
    // 未缴费记录
    title: '标题',
    preferentialPrice: '优惠后WSCT收费总额',
    settlementDepartment: '结算部门',
    settlementDate: '发费用结算单日期',
    // 我的汇款记录
    remittanceDate: '汇款时间',
    addRemittanceInformation: '新增汇款信息',
    remitter: '汇款人',
    remittanceAmount: '汇款单金额',
    currency: '币种',
    deleteTips: '确定删除当前记录吗？',
    totalRemittanceAmount: '汇款总金额',
    dateOfRemittance: '汇款日期',
    contacts: '联系人',
    contactPhone: '联系电话',
    postalCode: '邮政编码',
    address: '地址',
    remark: '备注',
    remittanceBasicInformation: '汇款基本信息',
    settlementList: '费用结算单列表',
    applicationNo: '申请编号',
    bailor: '委托人',
    factory: '生产厂',
    money: '金额',
    acceptanceDepartment: '受理部门',
    chargingStatus: '收费状态',
    valid: '本单是否有效',
    userPlaceholder: '用户名',
    amountCurrency: '金额币种',
    telephone: '电话',
    telephonePlaceholder: '请输入电话,例如:010-89718191',
    postcode: '邮编',
    remittanceBtn: '导入汇款信息',
    download: '下载',
    remittanceTips: '汇款信息模板',
    applicationNoBtn: '填写更多申请编号',
    applicationNoTips: '填写申请编号时，请填写完整的申请编号',
    remittanceInformation: '汇款信息',
    remitterTips: '请输入汇款人',
    dateOfRemittanceTips: '请选择汇款日期',
    contactsTips: '请输入联系人',
    contactPhoneTips: '请输入电话',
    // 费用通知
    serialNumber: '申请流水号',
    applicationTitle: '申请标题',
    notificationTime: '通知时间',
    applicationCategory: '申请类别',
    certificationCategory: '认证类别',
    productName: '产品名称',
    currentStage: '当前阶段',
    settlementType: '结算类型',
    paidExpenses: '已交费用',
    institutionPreferentialPrice: '检测机构收费总额优惠后',
    wsctPreferentialPrice: 'wsct收费总额优惠后',
    totalPreferentialPrice: '总费用优惠后',
    feeNotificationTime: '收费通知时间',
    immediateRemittance: '立即汇款'
  },
  communication: {
    scopeOfCertification: '认证范围',
    selectTypeTip: '在下列产品范围内能够从事认证业务，您选择的产品是（单选项）',
    selectScopeTitle: '选择认证范围',
    selectScopeText: '有关该类产品申请VOC认证的相关文件（请仔细阅读并按相关要求进行操作）',
    certificateHolderTitle: '1.申请证书持有人（带',
    certificateHolderTitle2: '*为必填项）',
    applicant: '申请人',
    manufacturer: '制造商',
    factoryInformation: '工厂信息',
    factoryName: '工厂名称',
    coApplicant: '同申请人',
    coManufacturer: '同制造商',
    contactPerson: '联络人',
    website: '网址',
    tradeLicense: '营业执照号码',
    invoiceMailingAddress: '发票信息及寄送地址',
    productParameter: '产品参数附件',
    productInfo: '产品信息',
    txtentOfTesting: '测试范围',
    productName: '产品名称',
    mainNumber: '主测型号',
    addingModel: '系列型号',
    tradeName: '商标',
    highestOperating: '最高工作频率(IC，晶振，谐振···等取频率最大值)',
    EUTOperatingTemperature: 'EUT Operating Temperature Range',
    EUTOperatingVoltage: 'EUT Operating Voltage Range',
    ratedVoltage: '额定电压',
    ratedPower: '额定功率',
    ratedCurrent: '额定电流',
    input: '输入',
    output: '输出',
    CEHardwareVersion: '硬件版本号(适用于 CE)',
    CESoftwareVersion: '软件版本号(适用于 CE)',
    adaptorMN: '适配器型号',
    batteryMN: '电池型号',
    adaptorRatings: '适配器参数',
    batterySpecification: '电池规格',
    password: '工程密码(适用于手机，平板产品)',
    protectionAgainst: '防触电等级',
    additionalInfo: '额外的信息，请加上附件',
    additionalInfoText1: '1、点击“+”按钮，选择上传附件。',
    additionalInfoText2: '2、每次上传附件的大小不能超过10M，最多上传5个文件。',
    additionalInfoText3: '3、支持的文件类型为jpg、gif、png、bmp、doc、docx、xlsx、xls、pdf、txt、zip、rar。',
    internationalScope: '适用国际标准',
    EUStandard: '欧盟、非洲东南亚国家标准',
    USACanadaStandard: '美国、加拿大美洲标准',
    othersStandard: '其他国家特定标准',
    other: '其他',
    subcontract: '分包',
    isAcceptTest: '本公司不接受WSCT以外的测试报告（如未选择，视为接受）',
    handlingInstruction: '样品处理方式',
    fetchByYourself: '自取',
    toBeCollected: '快递到付',
    toBeDestroyed: '由WSCT秘密销毁',
    destroyedReminder: '* 如未选择，视为接受由WSCT销毁样品。如选择退样，但样品超过12个月后不取样或不接受快递到付，则按照放弃处理，由WSCT销毁。',
    specialVATInvoice: '增值税专用发票',
    ordinaryVATInvoice: '增值税普通发票',
    proFormaInvoice: '形式发票',
    taxpayerNumber: '纳税人识别号',
    invoiceType: '发票类型：',
    invoiceHeader: '开发票抬头(发票名称须与付款名称一致)',
    bankCode: '银行账号',
    bankName: '开户银行:',
    receiver: '收件人',
    applyingItem: '申请项目',
    newApplication: '新申请',
    modification: '修改',
    specifyReport: '请提供原始报告 / 证书号码',
    standardUpdate: '标准更新',
    alternate: '报备零部件',
    alternateModels: '报备型号',
    grandeeCode: '等级代码',
    productCode: '产品代码',
    burundiRpc: 'Burundi RPC',
    companyNo: 'Company No',
    CertifiedProduct: '认证产品',
    inputAddress: '请输入公司地址',
    inputAddressEn: '请输入公司地址（英文）',
    inputContact: '请输入联络人',
    inputUrl: '请输入网址',
    inputTradeLicense: '请输入营业执照号码',
    pleaseInput: '请输入内容',
    pleaseInputCategory: '请选择认证产品',
    pleaseInputScope: '请选择认证范围',
    pleaseCompleteForm: '请完善表单信息',
    pleaseInputCertificate: '请选择证书类型',
    pleaseSelectSampleHand: '请选择样品处理方式',
    pleaseSelectApplyType: '请选择申请类型',
    pleaseSelectStandard: '请选择适用国际规范',
    upload5Attachments: '提示：最多上传五个附件！',
    filesTips: '如有电子版资料，例如额定值或技术参数的文件，可以作为申请书的电子附件上传',
    pleaseSelectInvoiceType: '请选择发票类型',
    viewContract: '查看检测认证合同'
  }
}
