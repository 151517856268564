import i18n from '@/locales'
import { getDateDay } from '@/utils/common'

// 国际化
const { t } = i18n.global as any

// 邮箱
export const checkEmail = (rule: any, value: any, callback: any) => {
  if (value === '') {
    callback(new Error(t('customsClearance.productConformity.inputEmail')))
  } else {
    // const newValue = value.trim()
    const validate = /^[a-zA-Z0-9]+([-_.][A-Za-z0-9]+)*@([a-zA-Z0-9-]+[-._])+[A-Za-z0-9]{2,6}$/
    if (validate.test(value)) {
      callback()
    } else {
      callback(new Error(t('customsClearance.productConformity.inputEmailReg')))
    }
  }
}

// 校验电话号码
export const checkTelephone = (rule: any, value: any, callback: any) => {
  // const newValue = value.trim()
  const validate = /^[0-9+-]{4,20}$/
  if (!value) {
    callback()
    return
  }
  if (validate.test(value)) {
    callback()
  } else {
    callback(new Error(t('customsClearance.productConformity.inputTelephoneReg')))
  }
}

// 校验手机号码
export const checkMobile = (rule: any, value: any, callback: any) => {
  // const newValue = value.trim()
  const validate = /^[0-9]{6,20}$/
  if (!value) {
    callback()
    return
  }
  if (validate.test(value)) {
    callback()
  } else {
    callback(new Error(t('register.pleaseTrueMobile')))
  }
}
// 校验手机号码
export const checkhandPhone = (rule: any, value: any, callback: any) => {
  // const newValue = value.trim()
  const validate = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  if (!value) {
    callback()
    return
  }
  if (validate.test(value)) {
    callback()
  } else {
    callback(new Error(t('register.pleaseTrueMobile')))
  }
}
// 日期必须大于等于当前日期
export const checkDate = async (rule: any, value: any, callback: any) => {
  if (!value) return
  const todayTime = await getDateDay(new Date())
  const dateTime = typeof (value) === 'string' ? new Date(value).getTime() : value.getTime()
  if (dateTime < new Date(todayTime).getTime()) {
    callback(new Error(t('customsClearance.productConformity.timeCannotEarlier')))
  } else {
    callback()
  }
}
