/**
 * 工作台接口封装
 */
// import request from '../utils/request'
import { createAxios } from '@/utils/request'

import { IApplyParams } from '../types/workBench'
const request = createAxios()

export const getDictionary = (data: string[]) => {
  return request<IApplyParams>({
    method: 'POST',
    url: '/workBench/list',
    data
  })
}

// 获取信息
export const getInfo = () => {
  return request({
    method: 'GET',
    url: '/workBench/list'
  })
}
// 获取消息
export const getnotify = (data: any) => {
  return request({
    method: 'GET',
    url: '/corner/notify',
    params: data
  })
}
// 标记为已读
export const notifyAlready = (ids: string[] | []) => {
  return request({
    method: 'POST',
    url: '/corner/notify/deal',
    data: {
      ids
    }
  })
}
// 消息详情
export const notifydetails = (id: any) => {
  return request({
    method: 'GET',
    url: `/corner/notify/${id}`
  })
}
// 公开文件
export const documents = (data:any) => {
  return request({
    method: 'GET',
    url: '/documents',
    params: data
  })
}
